import { cn } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { UILayout } from "../ui.layout";
import "./logo.css";

export default function GameMenu() {

	return (
		<AnimatePresence>
				<UILayout
					className="fullscreen pointer-events-auto"
					data-name="game-logo"
				>
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.25 }}
						className={cn(
							"absolute mx-auto uppercase top-[67.5%] z-[10000] flex flex-col w-full items-center",
						)}
					>
						{/* <div className="relative w-[400px] h-[162px] aspect-auto logo-image bg-contain" /> */}
						<div
							data-src="cloudlines.png"
							className="relative max-w-[400px] h-[162px] aspect-[1/2.4691358] logo-image border-0 w-[80%] bg-contain bg-scale bg-no-repeat"
							// style={{ backgroundSize: "100% auto" }}
						/>
					</motion.div>
				</UILayout>
		</AnimatePresence>
	);
}

import { validateSignup, validateEmail } from "@/lib/util.waitlist";
import { cn } from "@/lib/utils";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { LoadingSpinner } from "@/data/spinner";
import { CheckMark } from "@/data/checkmark";
import {
	ShopDiscordButton,
	ShopFormModal,
	ShopModalBlock,
	ShopModalButton,
} from "./itemShopModal";
import { Debug } from "@/lib/debug/debug";
import t from "@/data/translation.json"

export function ItemShopSignupForm() {
	const [emailInputValue, setEmailInputValue] = useState("");
	const [emailValid, setEmailValid] = useState(true);

	const {
		data: verificationResponse,
		refetch: submitSignup,
		isLoading,
		isError,
	} = useQuery({
		queryKey: ["signup", emailInputValue],
		queryFn: () => validateSignup({ email: emailInputValue }),
		enabled: false, // Don't run the query automatically
	});

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailInputValue(event.target.value);
	};

	const handleSubmit = async (event?: React.FormEvent<HTMLFormElement>) => {
		event?.preventDefault();
		const isValidEmail = validateEmail(emailInputValue);
		setEmailValid(isValidEmail);
		if (!isValidEmail) return;

		// Trigger the query
		await submitSignup();
	};

	const { canSignup } = useMemo(() => {
		if (!verificationResponse) return { canSignup: false };
		if (verificationResponse.canSignup) {
			Debug("ItemShop").capture("confirm_pass");
		}
		return { canSignup: verificationResponse.canSignup };
	}, [verificationResponse]);

	return (
		<ShopFormModal className="font-light rounded-lg" onSubmit={handleSubmit}>
			{/* Top block with title and descriptions */}
			<ShopModalBlock className="gap-4">
				<div className="text-5xl uppercase tracking-tighter bg-black text-[#d9d9d9] pr-1">
					SIGNUP
				</div>
				<div className="">{t.shop.signup}</div>
				<div className="text-xs">{t.shop.disclaimer}</div>
			</ShopModalBlock>
			<ShopModalBlock className="bg-[#D9D9D9]/0 py-6 px-4">
				<div
					className={cn(
						"border-t-[2px] border-b-[2px] border-gray-900 w-full",
						emailValid ? "" : "border-red-500/20",
					)}
				>
					<input
						name="email"
						id="email"
						type="email"
						placeholder="Email"
						autoComplete="on"
						value={emailInputValue}
						onChange={handleChange}
						onClick={(e) => {
							e.stopPropagation();
						}}
						className={cn(
							"bg-[#D9D9D9] border-0 h-12 w-full rounded-none px-2 py-2  ring-offset-background border-input placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset disabled:cursor-not-allowed disabled:opacity-50 focus-visible:bg-white/40",
						)}
						disabled={!!verificationResponse}
					/>
					<button type="submit" className="hidden" />
				</div>
			</ShopModalBlock>
			<ShopModalBlock className="h-full gap-4">
				<div className="flex-grow">
					{isLoading && <LoadingSpinner className="text-[#266818]" />}
					{isError && t.error}
					{canSignup && (
						<>
							<CheckMark className="text-[#266818] inline" />{" "}
							{t.shop.spot_available}
						</>
					)}
					{verificationResponse && !canSignup && (
						<div className="flex flex-col gap-2">
							<div>{t.shop.spots_available_zero}</div>
							<div className="flex flex-grow" />
							<div>{t.shop.join_discord}</div>
						</div>
					)}
				</div>
				<div
					className={cn(
						"self-end flex flex-row gap-2 items-center",
						canSignup && "hidden",
						verificationResponse && !canSignup && "self-center",
					)}
				>
					{!(verificationResponse && !canSignup) && (
						<ShopModalButton
							disabled={
								(verificationResponse && !canSignup) ||
								isLoading ||
								!emailValid ||
								emailInputValue.length < 1
							}
						>
							{t.continue}
						</ShopModalButton>
					)}
					{verificationResponse && !canSignup && (
						<ShopDiscordButton>{t.shop.button_discord}</ShopDiscordButton>
					)}
				</div>
			</ShopModalBlock>
		</ShopFormModal>
	);
}

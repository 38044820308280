import "../style/index.css";
import { ItemShop } from "./itemShop/itemShop";
import { AnimatePresence } from "framer-motion";
import GameMenu from "./gameMenu/gameMenu";

export default function UIRoot() {
	
	return (
		<>
			<AnimatePresence>
				<GameMenu key="game-menu" />
				<ItemShop key="item-shop" />
			</AnimatePresence>
		</>
	);
}

import type {
	HTMLAttributes,
	PropsWithChildren,
} from "react";
import { ItemShopSignupForm } from "./itemShopSignupForm";
import { ModalUILayout } from "../ui.layout";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/utils";

export function ItemShopOverlay({
	children,
	...props
}: PropsWithChildren & HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={cn(
				"fixed top-0 left-0 flex flex-col items-center justify-center w-screen h-full backdrop-blur-[1px] pointer-events-none select-all bg-black/20 ",
				props?.className,
			)}
			{...props}
		>
			{children}
		</div>
	);
}

export function ItemShopPages({
	children,
	...props
}: PropsWithChildren & HTMLAttributes<HTMLDivElement>) {
	return (
		<div
			className={cn(
				"flex flex-row items-center w-screen h-full bg-black/5 pointer-events-all select-all gap-8 overflow-x-auto justify-center",
				props?.className,
			)}
			{...props}
		>
			{children}
		</div>
	);
}

export function ItemShop() {
	return (
		<AnimatePresence>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.125 }}
				>
					{/* <div
						className="fixed pointer-events-all z-0 fullscreen"
						data-name="item-shop-click-catcher"
						onClick={(e) => {
							handleExit(e);
							e.stopPropagation();
						}}
					/> */}
					<ModalUILayout
						data-name="item-shop"
						className="pointer-events-none fullscreen -z-1"
					>
						<ItemShopOverlay>
							<ItemShopPages>
									<ItemShopSignupForm />
							</ItemShopPages>
						</ItemShopOverlay>
					</ModalUILayout>
				</motion.div>
		</AnimatePresence>
	);
}

import UIRoot from "./ui/components/ui.root";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import posthog from "posthog-js";
import { createHead, useSeoMeta } from "unhead";
import { APP_SEO_META } from "./data/app.data";

posthog.init("phc_ymxBknPyYI8zJHyQbm6yBSHp3FAu5mg5MDqibhSrbd4");

createHead();

const queryClient = new QueryClient();
function App() {

	useSeoMeta({
		...APP_SEO_META,
	});
  
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UIRoot />
      </QueryClientProvider>
    </>
  );
}

export default App;
